<template>
    <modal :id="id" title="Crop image" @close="close">
        <div class="cropper-img-container">
            <v-progress-circular v-if="isBusy" indeterminate color="primary" />
            <img ref="img" :src="image" alt="Source" />
        </div>
        <v-container class="pa-0 pt-4 cropper-actions">
            <v-row>
                <v-col v-if="canRotateImage" cols="auto">
                    <v-btn icon @click="rotateLeft">
                        <v-icon small>rotate-left</v-icon>
                    </v-btn>
                    <v-btn icon @click="rotateRight">
                        <v-icon small>rotate-right</v-icon>
                    </v-btn>
                </v-col>
                <v-col v-if="canToggleRatio" cols="auto">
                    <v-btn
                        class="ratio-toggle"
                        icon
                        :color="isVertical ? 'primary' : ''"
                        @click="toggleRatio"
                    >
                        <v-icon small>canvas-vertical</v-icon>
                    </v-btn>
                    <v-btn
                        class="ratio-toggle"
                        icon
                        :color="!isVertical ? 'primary' : ''"
                        @click="toggleRatio"
                    >
                        <v-icon small>canvas-horizontal</v-icon>
                    </v-btn>
                </v-col>
                <v-col v-if="canSlideZoom">
                    <v-slider
                        v-model="zoom"
                        dense
                        :min="min"
                        :step="step"
                        :max="max"
                        hide-details
                        @input="onZoom"
                    >
                        <template #prepend>
                            <v-btn
                                icon
                                :disabled="!canZoomOut"
                                @click="zoomOut(step)"
                            >
                                <v-icon small>magnifying-glass-minus</v-icon>
                            </v-btn>
                        </template>

                        <template #append>
                            <v-btn
                                icon
                                :disabled="!canZoomIn"
                                @click="zoomIn(step)"
                            >
                                <v-icon small>magnifying-glass-plus</v-icon>
                            </v-btn>
                        </template>
                    </v-slider>
                </v-col>
            </v-row>
        </v-container>

        <template #actions>
            <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
                @click="reset"
            >
                Reset
            </v-btn>
            <v-spacer class="d-flex justify-center">
                {{ progressMessage }}
            </v-spacer>
            <v-btn
                color="primary"
                :disabled="!canApply"
                :block="$vuetify.breakpoint.smAndDown"
                :loading="isWorking"
                @click="apply"
            >
                Apply
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import imageCompression from 'browser-image-compression';

import Cropper from 'cropperjs';

import { Modal } from '@/components/Modal';

import type { CropperOptions } from '.';

const ImageCropperProps = Vue.extend({
    name: 'AImageCroper',
    props: {
        id: {
            type: String,
            required: true
        },
        options: {
            type: Object as PropType<CropperOptions>,
            default() {
                return {
                    id: '',
                    file: '',
                    name: '',
                    instructions: {
                        data: {},
                        cropbox: {},
                        canvas: {},
                        zoom: {
                            factor: 0,
                            value: 0
                        }
                    },
                    options: {},
                    compression: {},
                    cropAspectRatioFromImage: false
                };
            }
        },
        canSlideZoom: {
            type: Boolean,
            default() {
                return true;
            }
        },
        canRotateImage: {
            type: Boolean,
            default() {
                return false;
            }
        },
        canRotateSelection: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class AImageCropper extends ImageCropperProps {
    $refs!: {
        img: HTMLImageElement;
    };

    cropper: InstanceType<typeof Cropper> | null = null;

    defaultOptions: Cropper.Options<HTMLImageElement> & {
        aspectRatio: number;
    } = {
        dragMode: 'move',
        viewMode: 0, // no restrictions
        zoomOnWheel: false,
        aspectRatio: 0, // allow free-transform
        autoCropArea: 0.98
    };

    defaultCompressionOptions = {
        maxSizeMB: 10,
        maxWidthOrHeight: 2560 // as defined in BE, MediaFilesTable::checkMediaDimensions
    };

    step = 1;

    zoom = 0;
    zoomFactor = 1;

    min = -90; // image should not scale less then 10%
    max = 100;

    cropperOptions = this.defaultOptions;

    isBusy = false;
    isWorking = false;

    progressMessage = '';

    get image() {
        return this.options.file;
    }

    get name() {
        return this.options.name;
    }

    get isVertical() {
        return this.cropperOptions.aspectRatio < 1;
    }

    get canZoomIn() {
        return this.zoom < this.max;
    }

    get canZoomOut() {
        return this.zoom > this.min;
    }

    get canToggleRatio() {
        if (this.canRotateSelection) {
            if (this.options.cropAspectRatioFromImage) {
                return true;
            }

            return this.cropperOptions.aspectRatio !== 0;
        }

        return false;
    }

    get canApply() {
        if (this.cropper) {
            const { width, height } = this.cropper.getCanvasData();

            return width > 0 && height > 0;
        }

        return false;
    }

    get compressionOptions() {
        const options = this.options.compression || {};

        return {
            ...this.defaultCompressionOptions,
            ...options
        };
    }

    @Watch('options', { deep: true })
    onOptionsChanged() {
        this.$nextTick(() => this.update());
    }

    destroyed() {
        this.destroyCropper();
    }

    destroyCropper() {
        if (this.cropper) {
            this.cropper.destroy();
        }
    }

    update() {
        this.setBusy();

        this.destroyCropper();

        this.resetData();

        this.initZoom();

        this.cropperOptions = {
            ...this.defaultOptions,
            ...this.options.options
        };

        this.cropper = new Cropper(this.$refs.img, {
            ...this.cropperOptions,
            ready: () => {
                if (this.cropper) {
                    this.cropper.setData(this.options.instructions.data);

                    this.cropper.setCanvasData(
                        this.options.instructions.canvas
                    );

                    this.cropper.setCropBoxData(
                        this.options.instructions.cropbox
                    );

                    this.init();

                    this.setBusy(false);
                }
            }
        });
    }

    initZoom() {
        this.zoom = this.options.instructions.zoom.value;
    }

    init() {
        if (this.cropper) {
            const { width, height, naturalWidth } = this.cropper.getImageData();

            this.zoomFactor =
                this.options.instructions.zoom.factor || width / naturalWidth;

            if (this.options.cropAspectRatioFromImage) {
                let ratio = 16 / 9;

                if (height > width) {
                    ratio = 9 / 16;
                }

                const needsFitting = this.cropperOptions.aspectRatio !== ratio;

                this.cropperOptions.aspectRatio = ratio;

                this.updateRatio();

                if (needsFitting) {
                    this.fit(0.1);
                }
            }
        }
    }

    fit(step: number) {
        if (this.cropper) {
            const { width, height } = this.cropper.getImageData();

            const { width: cropWidth, height: cropHeight } =
                this.cropper.getCropBoxData();

            if (width > cropWidth || height > cropHeight) {
                this.zoomOut(step);
                // keep zooming out until we fit
                this.fit(step);
            }
        }
    }

    reset() {
        if (this.cropper) {
            this.cropper.reset();
            this.resetData();
        }
    }

    resetData() {
        this.zoom = 0;
    }

    async apply() {
        this.setWorking();

        this.$emit('data', {
            id: this.options.id,
            data: JSON.parse(JSON.stringify(this.getData()))
        });

        await this.crop();

        this.setWorking(false);
    }

    async crop() {
        this.$emit('apply', await this.getCropData());
    }

    async getCropData() {
        const original = await fetch(this.image).then(r => r.blob());

        let crop = await this.getCropImage();

        if (!crop) {
            return null;
        }

        if (this.isTooBigCrop(crop)) {
            crop = await this.optimize(crop);
        }

        return {
            id: this.options.id,
            original,
            crop
        };
    }

    isTooBigCrop(crop: Blob) {
        return (
            crop.size >
            (this.compressionOptions?.maxSizeMB ||
                this.defaultCompressionOptions.maxSizeMB) *
                Math.pow(1024, 2)
        );
    }

    async optimize(crop: Blob) {
        this.say('Optimizing...');

        const optimized = await imageCompression(
            new File([crop], this.name, {
                type: crop.type
            }),
            {
                ...this.compressionOptions,
                onProgress: percent => {
                    this.say(`Optimizing... ${percent}% done`);
                }
            }
        );

        const buffer = await optimized.arrayBuffer();

        return new Blob([new Uint8Array(buffer)], {
            type: optimized.type
        });
    }

    getCropImage(): Promise<Blob | null> {
        return new Promise(resolve => {
            if (this.cropper) {
                const canvas = this.cropper.getCroppedCanvas();

                if (this.hasTransparency(canvas)) {
                    // save as PNG
                    canvas.toBlob(crop => {
                        resolve(crop);
                    });
                } else {
                    // JPEG is also fine
                    canvas.toBlob(
                        crop => {
                            resolve(crop);
                        },
                        'image/jpeg',
                        0.9
                    );
                }
            } else {
                resolve(null);
            }
        });
    }

    hasTransparency(canvas: HTMLCanvasElement) {
        const context = canvas.getContext('2d');

        if (context) {
            const imageData = context.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
            );

            for (let i = 0; i < imageData.data.length; i += 4) {
                if (imageData.data[i + 3] === 0) {
                    // transparent pixel
                    return true;
                }
            }
        }

        return false;
    }

    getData() {
        if (this.cropper) {
            return {
                canvas: this.cropper.getCanvasData(),
                cropbox: this.cropper.getCropBoxData(),
                data: this.cropper.getData(true),
                zoom: {
                    factor: this.zoomFactor,
                    value: this.zoom
                }
            };
        }
    }

    zoomOut(step: number) {
        this.zoom = this.zoom - step;

        this.onZoom();
    }

    zoomIn(step: number) {
        this.zoom = this.zoom + step;

        this.onZoom();
    }

    onZoom() {
        if (this.cropper) {
            let ratio = (this.zoomFactor * (100 + this.zoom)) / 100;

            if (this.zoom > 0) {
                ratio = (this.zoomFactor * (100 + this.zoom ** 2)) / 100;
            }

            this.cropper.zoomTo(ratio);
        }
    }

    toggleRatio() {
        // start clean
        this.reset();

        this.cropperOptions.aspectRatio =
            100 / (this.cropperOptions.aspectRatio * 100);

        this.updateRatio();
        // ensure we still fit
        this.fit(0.1);
    }

    updateRatio() {
        if (this.cropper) {
            this.cropper.setAspectRatio(this.cropperOptions.aspectRatio);
        }
    }

    rotate(degree: number) {
        if (this.cropper) {
            this.cropper.rotate(degree);
        }
    }

    rotateLeft() {
        this.rotate(-90);
    }

    rotateRight() {
        this.rotate(90);
    }

    close() {
        this.$emit('close');
    }

    setWorking(isWorking = true) {
        this.isWorking = isWorking;

        this.say(isWorking ? 'Cropping...' : '');
    }

    setBusy(isBusy = true) {
        this.isBusy = isBusy;
    }

    say(message = '') {
        this.progressMessage = message;
    }
}
</script>

<style lang="scss" scoped>
@import 'cropperjs/dist/cropper.css';

.cropper-img-container::v-deep {
    max-height: 100%;
    overflow: hidden;

    > [role='progressbar'] {
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
    }

    img {
        display: block;
        width: 100%;
        max-width: 100%;
        max-height: 50vh;
        //margin: 0 auto;
    }

    .cropper-container.cropper-bg {
        background-repeat: repeat;
    }

    .cropper-crop-box {
        .cropper-dashed {
            border-style: solid;
            border-color: $doger-blue !important;
            opacity: 1;
        }

        .cropper-point {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: $white;
            border: 1px solid $doger-blue;
            opacity: 1;

            &.point-n {
                top: -6px;
            }

            &.point-e {
                right: -6px;
            }

            &.point-s {
                bottom: -6px;
            }

            &.point-w {
                left: -6px;
            }

            &.point-ne {
                right: -5px;
                top: -5px;
            }

            &.point-nw {
                left: -5px;
                top: -5px;
            }

            &.point-sw {
                bottom: -5px;
                left: -5px;
            }

            &.point-se {
                bottom: -5px;
                right: -5px;
            }
        }
    }
}

.cropper-actions::v-deep {
    .ratio-toggle.primary--text {
        pointer-events: none;
    }
}
</style>
